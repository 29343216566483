<template>
  <el-collapse class="patient-plan no-select" accordion v-model="select">
    <el-collapse-item
      v-for="item in list"
      :key="item.value"
      :title="item.label"
      :name="item.value"
    >
      <el-collapse class="patient-plan-sub" accordion v-model="subSelect">
        <el-collapse-item
          v-for="sub in item.children"
          :key="sub.label"
          class="plan-item"
          :class="{ green: sub.state === 1, red: sub.state === 2 }"
          :name="sub.day"
        >
          <template #title>
            <div class="plan-info">
              <el-icon class="plan-icon">
                <circle-check-filled v-if="sub.state === 1" />
                <circle-close-filled v-else-if="sub.state === 2" />
                <clock v-else />
              </el-icon>
              <div>{{ sub.label }}</div>
              <div class="plan-date">{{ sub.date }}</div>
            </div>
          </template>
          <div class="plan-tips">
            <div v-if="sub.prefix === sub.suffix">
              可前后宽限{{ sub.suffix }}天
            </div>
            <div v-else>
              可向前宽限{{ sub.prefix }}天, 向后宽限{{ sub.suffix }}天
            </div>
            <div v-if="sub.remarks">说明: {{ sub.remarks }}</div>
            <div v-if="sub.index && sub.index.length > 0">
              <span>项目: </span>
              <span v-for="(group, i) in sub.index" :key="sub.day + '_' + i">
                <span v-if="i > 0">、</span>{{ group.name }}
                <template v-if="group.list.length > 1"
                  >(
                  <span
                    v-for="(index, a) in group.list"
                    :key="sub.day + '_' + i + '_' + a"
                  >
                    <span v-if="a > 0">、</span>{{ index.label }}
                  </span>
                  )</template
                >
              </span>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import {
  CircleCheckFilled,
  CircleCloseFilled,
  Clock,
} from "@element-plus/icons";

export default {
  name: "PatientPlan",
  components: {
    CircleCheckFilled,
    CircleCloseFilled,
    Clock,
  },
  props: {
    content: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    select: "",
    subSelect: "",
    list: [],
  }),
  methods: {
    init() {
      this.buildList();
    },
    buildList() {
      let list = [];
      let cache = {};
      let content = this.content;
      // 按照距离天数重新排序
      content.sort(function (a, b) {
        return a.day - b.day;
      });
      for (let i in content) {
        let day = content[i].day;
        // 计算年份
        let year = parseInt(day % 365 === 0 ? day / 365 : day / 365 + 1);
        let tag = "第" + year + "年";
        content[i].value = parseInt(i) + 1;
        if (cache[tag] === undefined) cache[tag] = [content[i]];
        else cache[tag].push(content[i]);
      }
      // 聚合只有一次随访的年份
      this.polyList(cache);
      for (let key in cache) {
        let label = key;
        label += " " + cache[key][0].date.substring(0, 7);
        if (cache[key].length > 1)
          label +=
            " ~ " + cache[key][cache[key].length - 1].date.substring(0, 7);
        list.push({
          value: key,
          label: label.replaceAll("-", "/"),
          children: cache[key],
        });
      }
      this.list = list;
      this.buildSelect();
    },
    polyList(cache) {
      let years = [];
      let alone = [];
      for (let tag in cache) {
        if (cache[tag].length == 1) {
          let day = cache[tag][0].day;
          // 计算年份
          let year = parseInt(day % 365 === 0 ? day / 365 : day / 365 + 1);
          years.push(year);
          alone.push(cache[tag][0]);
          delete cache[tag];
        }
      }
      if (years.length > 0)
        cache["第" + years[0] + "至" + years[years.length - 1] + "年"] = alone;
    },
    buildSelect() {
      for (let key in this.list) {
        for (let i in this.list[key].children) {
          let state = this.list[key].children[i].state;
          if (state === undefined || state === 0) {
            this.select = this.list[key].value;
            this.subSelect = this.list[key].children[i].day;
            return false;
          }
        }
      }
    },
  },
};
</script>

<style>
.patient-plan .el-collapse-item__header {
  font-size: 14px;
}

.patient-plan .el-collapse-item__content {
  padding: 0 5px;
}

.patient-plan .el-collapse-item__wrap {
  border-radius: 8px;
}

.patient-plan-sub .el-collapse-item__header {
  padding-left: 5px !important;
  background-color: #f4f4f4;
  border-radius: 0 !important;
  font-size: 14px;
  height: 30px;
}

.patient-plan-sub .el-collapse-item__content {
  background-color: #f4f4f4;
}
</style>

<style scoped>
.patient-plan-sub {
  border-bottom: none;
}

.plan-item {
  background-color: #f4f4f4;
  margin-bottom: 5px;
  border-radius: 6px;
}

.plan-info {
  align-items: center;
  position: relative;
  line-height: 22px;
  font-size: 14px;
  display: flex;
  width: 100%;
}

.plan-tips {
  padding-bottom: 5px;
  line-height: 16px;
  margin-left: 23px;
  font-size: 12px;
  color: #777;
}

.plan-icon {
  margin-right: 3px;
  font-size: 20px;
  color: #999;
}

.green .plan-icon {
  color: #1db100;
}

.red .plan-icon {
  color: #db0000;
}

.plan-date {
  position: absolute;
  right: 5px;
}
</style>