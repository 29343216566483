<template>
  <el-dialog
    custom-class="edit-dialog"
    v-model="examine.show"
    :title="examine.agree ? '同意申请' : '拒绝申请'"
    :close-on-click-modal="!loading"
    :close-on-press-escape="!loading"
    :show-close="!loading"
    width="350px"
  >
    <el-form :disabled="loading">
      <el-form-item class="mb-0" required label="计划模板" v-if="examine.agree">
        <el-select
          clearable
          v-model="examine.plan"
          fit-input-width
          placeholder="请选择模板"
        >
          <el-option
            v-for="item in list"
            :key="'transplant_' + item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="mb-0" required label="拒绝原因" v-else>
        <el-input v-model="examine.msg" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button :disabled="loading" @click="examine.show = false"
          >取消</el-button
        >
        <el-button :loading="loading" type="primary" @click="save"
          >提交</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { follow, patient } from "../../plugin/api";

export default {
  name: "PatientExamine",
  data: () => ({
    tid: 0,
    list: [],
    loading: false,
    examine: {
      show: false,
      agree: false,
      plan: 0,
      msg: "",
      id: 0,
    },
  }),
  emits: ["update"],
  methods: {
    open(agree, id, tid) {
      this.tid = tid;
      if (agree) this.queryList(agree, id);
      else this.init(agree, id, 0);
    },
    init(agree, id, plan) {
      this.loading = false;
      this.examine = {
        show: true,
        agree,
        plan,
        msg: "",
        id,
      };
    },
    queryList(agree, id) {
      follow
        .getTidList(this.tid)
        .then((res) => {
          if (res.state) {
            this.list = res.data;
            if (this.list.length > 0) this.init(agree, id, this.list[0].id);
            else this.init(agree, id, "");
          } else {
            this.examine.show = false;
            this.showWarning(res.message ? res.message : "发生意料之外的错误");
          }
        })
        .catch(() => {
          this.examine.show = false;
        });
    },
    showWarning(msg) {
      this.$message.warning({
        message: msg,
        center: true,
      });
      return false;
    },
    save() {
      let form = this.examine;
      this.loading = true;
      patient
        .examine(form.id, form.agree, form.agree ? form.plan : form.msg)
        .then((res) => {
          this.examine.show = false;
          if (res.state) {
            this.$emit("update");
            this.$message.success({
              message: "操作成功",
              center: true,
            });
          } else {
            this.showWarning("操作失败");
          }
        })
        .catch(() => {
          this.examine.show = false;
        });
    },
  },
};
</script>

<style scoped>
</style>