<template>
  <div class="container patient-info">
    <patient-head :loading="loading" :info="info">
      <el-button-group>
        <el-button
          plain
          :disabled="info.state != 2"
          :loading="loading.send"
          @click="sendPatientFollow"
        >
          <el-icon v-if="!loading.send"><promotion /></el-icon>
          <span>发送随访通知</span>
        </el-button>
        <el-button :disabled="!info.fromHIS" type="primary">同步数据</el-button>
      </el-button-group>
    </patient-head>
    <el-row :gutter="10">
      <el-col class="patient-tabs" :span="18">
        <div class="card pa-0 tabs-card mb-0" v-loading="loading.details">
          <template v-if="!loading.error">
            <patient-tabs :welcome="welcome" :pid="parseInt(info.id)" />
          </template>
          <el-result
            v-else
            icon="error"
            title="加载失败"
            style="padding-top: calc(50vh - 200px)"
          />
        </div>
      </el-col>
      <el-col :span="6">
        <el-affix :offset="10" style="height: auto; width: auto">
          <div class="card pa-0" v-loading="loading.info" style="height: 105px">
            <div class="date-tips" v-if="welcome.date">
              <div class="date-item">
                <div>术后</div>
                <div class="date-number">{{ welcome.date.surgeryDate }}</div>
                <div>天</div>
              </div>
              <div class="date-item">
                <div>出院</div>
                <div class="date-number">{{ welcome.date.dischargeDate }}</div>
                <div>天</div>
              </div>
            </div>
          </div>
          <div class="follow-plan card list-card" v-loading="loading.plan">
            <template v-if="!loading.error">
              <div>
                <div
                  class="btn float-right"
                  v-if="plan.content && plan.content.length > 0"
                  @click="editPlan"
                >
                  <el-icon><edit /></el-icon>
                  <span class="ml-5">修改计划</span>
                </div>
                <div class="card-head">随访计划</div>
              </div>
              <el-scrollbar height="calc(100vh - 415px)">
                <patient-plan
                  v-if="plan.content && plan.content.length > 0"
                  :content="plan.content"
                  ref="plan"
                />
              </el-scrollbar>
            </template>
            <el-result
              v-else
              icon="error"
              title="加载失败"
              style="padding-top: calc(50vh - 220px)"
            />
          </div>
          <div class="follow-foot">
            <div class="float-right">操作日志</div>
            <div>来源: HIS</div>
          </div>
        </el-affix>
      </el-col>
    </el-row>
    <div v-if="info.state && info.state !== 2" class="examine-mask no-select">
      <el-result
        icon="error"
        title="绑定申请已拒绝"
        :sub-title="info.auditTime"
        v-if="info.state === 3"
      >
        <template #extra>
          <div style="margin-top: -30px">
            <small>原因: {{ info.auditMessage }}</small>
          </div>
        </template>
      </el-result>
      <el-result
        icon="info"
        title="绑定申请"
        sub-title="请确认患者是否归您负责, 本院患者可使用右上角按钮同步HIS数据"
        v-else
      >
        <template #extra>
          <el-button type="success" @click="openExamine(true)"
            >同意申请</el-button
          >
          <el-button type="danger" @click="openExamine(false)">拒绝</el-button>
          <el-button plain>移交</el-button>
        </template>
      </el-result>
    </div>
    <patient-examine ref="patientExamine" @update="queryInfo" />
  </div>
</template>

<script>
import PatientExamine from "../../components/patient/patient-examine.vue";
import PatientTabs from "../../components/patient/patient-tabs.vue";
import PatientPlan from "../../components/patient/patient-plan.vue";
import PatientHead from "../../components/patient/patient-head.vue";
import { Edit, Promotion } from "@element-plus/icons";
import { patient, notice } from "../../plugin/api";
import { date } from "../../plugin/util";

export default {
  name: "PatientInfo",
  components: {
    PatientExamine,
    PatientTabs,
    PatientPlan,
    PatientHead,
    Edit,
    Promotion,
  },
  data: () => ({
    loading: {
      info: true,
      plan: true,
      details: true,
      send: false,
      error: false,
    },
    examine: {
      show: false,
      agree: false,
      plan: 0,
      msg: "",
    },
    info: {
      id: 0,
      name: "",
      fromHIS: false,
    },
    plan: {},
    welcome: {},
  }),
  methods: {
    init() {
      this.loading = {
        info: true,
        plan: true,
        details: true,
        send: false,
        error: false,
      };
      this.info = {
        id: this.$route.params.id,
        name: "",
        fromHIS: false,
      };
      this.queryInfo();
    },
    queryInfo() {
      patient
        .getInfo(this.info.id, true)
        .then((res) => {
          if (res.state) {
            setTimeout(() => {
              this.info = res.data.info;
              this.buildDate("auditTime", "yyyy-mm-dd  HH:MM:SS");
              this.loading.info = false;
              if (res.data.plan) this.plan = res.data.plan;
              this.buildWelcome(res.data);
            }, 300);
            setTimeout(() => {
              if (this.plan.content && this.plan.content.length > 0)
                this.$refs.plan.init();
              this.loading.plan = false;
            }, 800);
            setTimeout(() => {
              this.loading.details = false;
            }, 1300);
          } else {
            this.loading = {
              info: false,
              plan: false,
              details: false,
              send: false,
              error: true,
            };
          }
        })
        .catch(() => {
          this.loading = {
            info: false,
            plan: false,
            details: false,
            send: false,
            error: true,
          };
        });
    },
    buildWelcome(data) {
      let day = 1000 * 60 * 60 * 24;
      if (!data.plan) return false;
      let content = data.plan.content;
      let plan = {
        last: {},
        now: {},
        next: {},
      };
      let now = parseFloat(
        (new Date().getTime() -
          (data.plan.based == 1
            ? data.info.surgeryDate
            : data.info.dischargeDate)) /
          day
      ).toFixed(0);
      for (let i in content) {
        let item = content[i];
        if (item.day - item.prefix <= now && item.day + item.suffix >= now) {
          plan.now = item;
          break;
        }
      }
      for (let i in content) {
        let item = content[i];
        let next1 =
          parseInt(i) <= content.length ? content[parseInt(i)] : {};
        let next2 =
          parseInt(i) + 1 <= content.length ? content[parseInt(i) + 1] : {};
        if (item.state == undefined || item.state == 0) {
          // 获取上次随访
          if (parseInt(i) - 1 >= 0 && content[parseInt(i) - 1].day != plan.now.day)
            plan.last = content[parseInt(i) - 1];
          else if (parseInt(i) - 2 >= 0) plan.last = content[parseInt(i) - 2];
          // 获取下次随访
          if (plan.now.day == undefined) plan.next = item;
          else plan.next = plan.now.day == item.day ? next2 : next1;
          break;
        }
      }
      let surgeryDate = parseFloat(
        (new Date().getTime() - data.info.surgeryDate) / day
      ).toFixed(0);
      let dischargeDate = parseFloat(
        (new Date().getTime() - data.info.dischargeDate) / day
      ).toFixed(0);
      this.welcome = {
        plan,
        progress: data.info.progress,
        date: {
          based: data.plan.based,
          surgeryDate,
          dischargeDate,
        },
      };
    },
    buildDate(name, code) {
      this.info[name] = date.format(this.info[name], code);
    },
    openExamine(agree) {
      this.$refs.patientExamine.open(
        agree,
        this.info.id,
        this.info.transplantType
      );
    },
    editPlan() {
      this.$router.push("/patient/" + this.info.id + "/plan");
    },
    sendPatientFollow() {
      this.$confirm(
        "确认要向“" +
          this.info.name +
          "”发送随访通知? 系统将会按照随访计划向随访患者的微信发送订阅消息.",
        "发送随访通知",
        {
          confirmButtonText: "确认发送",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.loading.send = true;
        notice
          .sendPatientFollow(this.info.id)
          .then((res) => {
            this.loading.send = false;
            if (res.state) {
              this.$message.success({
                message: "发送成功",
                center: true,
              });
            } else {
              this.$message.warning({
                message: "发送失败, " + res.message,
                center: true,
              });
            }
          })
          .catch(() => {
            this.loading.send = false;
          });
      });
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    $route: {
      handler(to) {
        if (to.name === "PatientInfo" && to.params.id != this.info.id)
          this.init();
      },
      deep: true,
    },
  },
};
</script>

<style>
.follow-plan .el-collapse-item__header {
  padding-left: 10px;
}

.follow-plan .el-collapse-item__header:last-child {
  border-radius: 0 0 8px 8px;
}

.patient-info .el-result {
  padding: 10px;
}
</style>
<style scoped>
.follow-plan {
  height: calc(100vh - 385px);
}

.follow-plan .btn {
  margin-right: 5px;
  margin-top: -5px;
}

.patient-tabs {
  position: relative;
}

.btn {
  background-color: #f4f4f4;
  color: #222;
}

.btn:hover {
  background-color: #e2e2e2;
  color: #222;
}

.btn:active,
.btn.active {
  background-color: #d4d3d3;
  color: #222;
}

.patient-tabs .btn {
  position: absolute;
  z-index: 99;
  right: 10px;
  top: 5px;
}

.follow-foot {
  font-size: 14px;
  padding: 0 5px;
  color: #999;
}

.tabs-card {
  min-height: calc(100vh - 230px);
}

.examine-mask {
  background-color: rgba(244, 244, 244, 0.2);
  -webkit-backdrop-filter: blur(6px);
  height: calc(100vh - 230px);
  backdrop-filter: blur(6px);
  width: calc(100vw - 155px);
  justify-content: center;
  align-items: center;
  position: fixed;
  display: flex;
  z-index: 2000;
  left: 150px;
  top: 215px;
}

.date-tips {
  margin: 0 auto;
  display: flex;
  width: 100%;
}

.date-item {
  border-radius: 8px;
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
  padding: 10px;
  width: 100px;
}

.date-number {
  font-size: 36px;
}
</style>