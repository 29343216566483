<template>
  <el-tabs
    v-model="select"
    class="patient-tabs no-select"
    @tab-click="switchTab"
  >
    <el-tab-pane label="随访提示" name="info">
      <div class="progress" v-if="welcome.progress">
        <div class="plan-number">计划 {{ welcome.progress.total }} 次</div>
        <el-progress
          text-inside
          :show-text="false"
          :stroke-width="12"
          :percentage="
            ((welcome.progress.total - welcome.progress.waiting) /
              welcome.progress.total) *
            100
          "
          status="success"
        />
        <div class="state-box">
          <span class="state-complete"
            >完成 {{ welcome.progress.complete }} 次</span
          >
          ·
          <span class="state-overdue"
            >过期 {{ welcome.progress.overdue }} 次</span
          >
        </div>
      </div>
      <div class="plan-box" v-if="welcome.plan">
        <div class="plan-item" v-if="welcome.plan.now && welcome.plan.now.day">
          <div class="float-right text-right">
            <div>{{ welcome.plan.now.date }}</div>
            <span class="overall" v-if="welcome.plan.now.overall">
              <el-icon><star-filled /></el-icon>
              <span>全面复查</span>
            </span>
          </div>
          <div class="mb-5" style="display: flex; align-items: center">
            <div style="font-size: 21px">
              {{ welcome.plan.now.label }}
            </div>
            <el-tag class="ml-5" effect="dark">当前随访</el-tag>
          </div>
          <div v-if="welcome.plan.now.remarks">
            <span style="color: #6e6e6e">节点说明:</span>
            {{ welcome.plan.now.remarks }}
          </div>
          <div>
            <div style="color: #6e6e6e">检查项目:</div>
            <div
              v-for="(item, i) in welcome.plan.now.index"
              :key="welcome.plan.now.day + '_' + i"
            >
              <span>{{ i + 1 }}、{{ item.name }}</span>
              <template v-if="item.list.length > 0">
                <span>: </span>
                <span
                  v-for="(sub, x) in item.list"
                  :key="welcome.plan.now.day + '_' + i + '_' + x"
                >
                  <span v-if="x > 0">、</span>{{ sub.label }}
                </span>
              </template>
            </div>
          </div>
        </div>
        <div class="plan-item" v-else>
          <div class="plan-tips">当前暂无随访计划</div>
        </div>
        <div class="sub-box">
          <div
            class="plan-item"
            v-if="welcome.plan.last && welcome.plan.last.day"
          >
            <div class="float-right text-right">
              <div>{{ welcome.plan.last.date }}</div>
              <span class="overall" v-if="welcome.plan.last.overall">
                <el-icon><star-filled /></el-icon>
                <span>全面复查</span>
              </span>
            </div>
            <div class="mb-5" style="display: flex; align-items: center">
              <div style="font-size: 21px">
                {{ welcome.plan.last.label }}
              </div>
              <el-tag type="info" class="ml-5" effect="dark">上次随访</el-tag>
            </div>
            <div v-if="welcome.plan.last.remarks">
              <span style="color: #6e6e6e">节点说明:</span>
              {{ welcome.plan.last.remarks }}
            </div>
            <div>
              <div style="color: #6e6e6e">检查项目:</div>
              <div
                v-for="(item, i) in welcome.plan.last.index"
                :key="welcome.plan.last.day + '_' + i"
              >
                <span>{{ i + 1 }}、{{ item.name }}</span>
                <template v-if="item.list.length > 1">
                  <span>: </span>
                  <span
                    v-for="(sub, x) in item.list"
                    :key="welcome.plan.last.day + '_' + i + '_' + x"
                  >
                    <span v-if="x > 0">、</span>{{ sub.label }}
                  </span>
                </template>
              </div>
            </div>
          </div>
          <div class="plan-item" v-else>
            <div class="plan-tips">暂无历史随访计划</div>
          </div>
          <div
            class="plan-item"
            v-if="welcome.plan.next && welcome.plan.next.day"
          >
            <div class="float-right text-right">
              <div>{{ welcome.plan.next.date }}</div>
              <span class="overall" v-if="welcome.plan.next.overall">
                <el-icon><star-filled /></el-icon>
                <span>全面复查</span>
              </span>
            </div>
            <div class="mb-5" style="display: flex; align-items: center">
              <div style="font-size: 21px">
                {{ welcome.plan.next.label }}
              </div>
              <el-tag type="warning" class="ml-5" effect="dark"
                >下次随访</el-tag
              >
            </div>
            <div v-if="welcome.plan.next.remarks">
              <span style="color: #6e6e6e">节点说明:</span>
              {{ welcome.plan.next.remarks }}
            </div>
            <div>
              <div style="color: #6e6e6e">检查项目:</div>
              <div
                v-for="(item, i) in welcome.plan.next.index"
                :key="welcome.plan.next.day + '_' + i"
              >
                <span>{{ i + 1 }}、{{ item.name }}</span>
                <template v-if="item.list.length > 0">
                  <span>: </span>
                  <span
                    v-for="(sub, x) in item.list"
                    :key="welcome.plan.next.day + '_' + i + '_' + x"
                  >
                    <span v-if="x > 0">、</span>{{ sub.label }}
                  </span>
                </template>
              </div>
            </div>
          </div>
          <div class="plan-item" v-else>
            <div class="plan-tips">已无下次随访计划</div>
          </div>
        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane label="随访记录" name="follow" v-loading="info.follow.loading">
      <template v-if="info.follow.list.length > 0">
        <el-table
          :data="info.follow.list"
          height="calc(100vh - 310px)"
          :row-style="{ cursor: 'pointer' }"
          style="width: 100%; border-radius: 8px"
          @row-click="openFollow"
        >
          <el-table-column
            prop="date"
            label="随访时间"
            width="100"
            fixed
          ></el-table-column>
          <el-table-column label="随访类型" width="75">
            <template #default="scope">
              <div v-if="scope.row.type === 1">计划复诊</div>
              <div v-else-if="scope.row.type === 2">临时复诊</div>
            </template>
          </el-table-column>
          <el-table-column label="就诊医院" min-width="220">
            <template #default="scope">
              <div class="line1">{{ scope.row.hospitalName }}</div>
            </template>
          </el-table-column>
          <el-table-column label="接诊医生" width="135">
            <template #default="scope">
              <div class="line1">
                {{ scope.row.department }} - {{ scope.row.doctor }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="器官状态" width="75">
            <template #default="scope">
              <template v-if="scope.row.feedback">
                <div
                  style="color: #1fab07"
                  v-if="scope.row.feedback.survival === 1"
                >
                  器官带功
                </div>
                <div
                  style="color: #dd1111"
                  v-else-if="scope.row.feedback.survival === 2"
                >
                  器官失功
                </div>
                <div
                  style="color: #c3c3c3"
                  v-else-if="scope.row.feedback.survival === 3"
                >
                  器官移除
                </div>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="随访情况" min-width="270">
            <template #default="scope">
              <template v-if="scope.row.feedback">
                <el-tag
                  type="warning"
                  effect="dark"
                  v-if="scope.row.feedback.complication"
                  >并发症</el-tag
                >
                <el-tag
                  type="warning"
                  class="ml-5"
                  effect="dark"
                  v-if="scope.row.feedback.exclusion"
                  >排异反应</el-tag
                >
                <el-tag
                  type="warning"
                  class="ml-5"
                  effect="dark"
                  v-if="scope.row.feedback.infected"
                  >感染</el-tag
                >
                <el-tag
                  type="warning"
                  class="ml-5"
                  effect="dark"
                  v-if="scope.row.feedback.drug"
                  >药物异常</el-tag
                >
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="adjustmentDate"
            label="调药情况"
            min-width="200"
          >
            <template #default="scope">
              <template v-if="scope.row.feedback">
                <el-button
                  v-for="(item, index) in scope.row.feedback.adjustment"
                  :key="scope.row.id + '_' + index"
                  size="small"
                  plain
                  @click.stop="openAlert(item)"
                  >{{ item.date }}</el-button
                >
              </template>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center" width="80" fixed="right">
            <template #default="scope">
              <el-tag type="warning" effect="dark" v-if="scope.row.state === 1"
                >待反馈</el-tag
              >
              <el-tag
                type="success"
                effect="dark"
                v-else-if="scope.row.state === 2"
                >已反馈</el-tag
              >
              <el-tag type="info" effect="dark" v-else>已关闭</el-tag>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="info.follow.total"
          :page-size="number"
          @current-change="queryFollowList"
        />
      </template>
      <el-empty
        description="暂无随访记录"
        v-else
        style="padding: calc(50vh - 300px) 0 calc(50vh - 230px) 0"
      ></el-empty>
    </el-tab-pane>
    <el-tab-pane label="血药浓度" name="drug">
      <el-empty description="暂无血药浓度数据"></el-empty>
    </el-tab-pane>
    <el-tab-pane label="日常体征" name="vital" v-loading="info.vital.loading">
      <div class="mt-10">
        <el-button plain class="float-right mr-10" @click="queryDailyTest">
          <el-icon><refresh /></el-icon>
          <span>刷新</span>
        </el-button>
        <el-form :inline="true">
          <el-form-item class="ml-10 mb-10">
            <el-date-picker
              v-model="info.vital.date"
              type="daterange"
              unlink-panels
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="changeVitalDate"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <template v-if="info.vital.list.length > 0">
        <el-scrollbar style="padding: 0 5px">
          <div class="chart-list">
            <template
              v-for="item in info.vital.temp"
              :key="'chart_' + item.name"
            >
              <div style="position: relative" v-if="item.chart">
                <el-button
                  plain
                  class="full-screen"
                  :icon="FullScreen"
                  circle
                  @click="fullScreen(item.id)"
                  v-if="!item.hide"
                />
                <div class="chart-item" :id="'vital_' + item.id">
                  <div class="chart-tips" v-if="item.hide">
                    暂无{{ item.name }}数据
                  </div>
                </div>
              </div>
            </template>
          </div>
        </el-scrollbar>
        <el-table
          :data="info.vital.list"
          style="width: 100%; border-radius: 8px"
          height="calc(100vh - 565px)"
        >
          <el-table-column
            fixed
            prop="date"
            sortable
            :sort-orders="['ascending', 'descending']"
            label="日期"
            width="100"
          />
          <template v-for="item in info.vital.temp" :key="item.name">
            <el-table-column
              v-if="item.list.length === 1"
              :prop="'' + item.list[0].id"
              :label="
                item.list[0].label +
                (item.list[0].unit ? ' (' + item.list[0].unit + ')' : '')
              "
              min-width="140px"
              align="right"
              sortable
            />
            <el-table-column header-align="center" v-else :label="item.name">
              <el-table-column
                v-for="sub in item.list"
                :key="item.name + '_' + sub.name"
                :prop="'' + sub.id"
                :label="sub.label + (sub.unit ? ' (' + sub.unit + ')' : '')"
                min-width="140px"
                align="right"
                sortable
              />
            </el-table-column>
          </template>
          <el-table-column
            prop="abnormal"
            label="异常症状"
            align="center"
            show-overflow-tooltip
            width="120px"
          />
          <el-table-column
            prop="drug"
            label="其他药物"
            align="center"
            show-overflow-tooltip
            width="120px"
          />
        </el-table>
      </template>
      <el-empty
        description="暂无生命体征数据"
        v-else
        style="padding: calc(50vh - 310px) 0 calc(50vh - 230px) 0"
      ></el-empty>
      <el-dialog v-model="info.vital.fullScreen" title="" fullscreen>
        <div id="vital_fullscreen"></div>
      </el-dialog>
    </el-tab-pane>
    <el-tab-pane label="留言咨询" name="chat" v-loading="info.problem.loading">
      <template v-if="info.problem.list.length > 0">
        <el-table
          :data="info.problem.list"
          height="calc(100vh - 310px)"
          :row-style="{ cursor: 'pointer' }"
          style="width: 100%; border-radius: 8px"
          @row-click="openChat"
        >
          <el-table-column prop="id" label="编号" width="90" />
          <el-table-column prop="title" label="主题" min-width="300" />
          <el-table-column prop="time" label="创建时间" width="170" />
          <el-table-column label="状态" align="center" width="80" fixed="right">
            <template #default="scope">
              <el-tag effect="dark" v-if="scope.row.state === 1">进行中</el-tag>
              <el-tag
                type="success"
                effect="dark"
                v-else-if="scope.row.state === 2"
                >已解决</el-tag
              >
              <el-tag type="info" effect="dark" v-else>已关闭</el-tag>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="info.problem.total"
          :page-size="number"
          @current-change="getProblemList"
        />
      </template>
      <el-empty
        description="暂无留言咨询"
        v-else
        style="padding: calc(50vh - 300px) 0 calc(50vh - 230px) 0"
      ></el-empty>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { markRaw } from "vue";
import { Refresh, StarFilled, FullScreen } from "@element-plus/icons";
import { follow, index, problem } from "../../plugin/api";
import { date } from "../../plugin/util";
import * as echarts from "echarts";

export default {
  name: "PatientTabs",
  components: {
    Refresh,
    StarFilled,
  },
  props: {
    welcome: {
      type: Object,
      default: () => {
        return {};
      },
    },
    pid: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    select: "info",
    FullScreen: markRaw(FullScreen),
    info: {
      follow: {
        sort: "desc",
        page: 1,
        total: 0,
        justNotLook: false,
        justOur: false,
        loading: true,
        list: [],
        map: {},
      },
      drug: {},
      vital: {
        loading: true,
        fullScreen: false,
        date: [],
        list: [],
        temp: [],
      },
      problem: {
        loading: true,
        list: [],
        total: 0,
        page: 1,
      },
    },
    form: {
      vital: {
        date: "",
      },
    },
    number: 9,
    page: 0,
  }),
  methods: {
    init() {
      let height = document.body.clientHeight;
      if (height > 768) this.number = 9 + parseInt((height - 768) / 40);
      this.initConfig();
      setTimeout(() => {
        this.switchTab({ props: { name: this.select } });
      }, 300);
    },
    initConfig() {
      this.info.vital.date = [
        new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30),
        new Date(),
      ];
      setTimeout(() => {
        index.getDailyTestTemp(this.pid).then((res) => {
          for (let a in res.data) {
            let chart = false;
            for (let b in res.data[a].list) {
              let item = res.data[a].list[b];
              if (item.input != "text") chart = true;
            }
            res.data[a].chart = chart;
          }
          this.info.vital.temp = res.data;
        });
      }, 300);
    },
    queryFollowNumber() {
      this.info.follow.loading = true;
      follow
        .getNumber(this.pid)
        .then((res) => {
          this.info.follow.total = res.data;
          if (this.info.follow.total > 0) this.queryFollowList(1);
          else {
            this.info.follow.list = [];
            this.info.follow.loading = false;
          }
        })
        .catch(() => {
          this.info.follow.total = 0;
          this.info.follow.loading = false;
        });
    },
    queryFollowList(page) {
      this.page = page;
      follow
        .getList(this.pid, page, this.number)
        .then((res) => {
          for (let i in res.data) {
            this.buildDate(res.data[i], "date", "yyyy-mm-dd");
            this.buildDate(res.data[i], "addTime", "yyyy-mm-dd HH:MM:SS");
          }
          this.info.follow.list = res.data;
          this.info.follow.loading = false;
        })
        .catch(() => {
          this.info.follow.loading = false;
        });
    },
    getProblemNumber() {
      this.info.problem.loading = true;
      problem
        .getNumber({ name: this.pid })
        .then((res) => {
          this.info.problem.total = res.data;
          if (this.info.problem.total > 0) this.getProblemList(1);
          else {
            this.info.problem.list = [];
            this.info.problem.loading = false;
          }
        })
        .catch(() => {
          this.info.problem.total = 0;
          this.info.problem.loading = false;
        });
    },
    getProblemList(page) {
      this.info.problem.page = page;
      problem
        .getList({ name: this.pid }, page, this.number)
        .then((res) => {
          setTimeout(() => {
            for (let i in res.data) {
              this.buildDate(res.data[i], "time", "yyyy-mm-dd HH:MM:SS");
            }
            this.info.problem.list = res.data;
            this.info.problem.loading = false;
          }, 500);
        })
        .catch(() => {
          this.info.problem.loading = false;
        });
    },
    buildDate(obj, name, code) {
      obj[name] = date.format(obj[name], code);
    },
    queryDailyTest() {
      this.info.vital.loading = true;
      let date = this.buildDailyTestDate();
      index
        .getDailyTestList({
          pid: this.pid,
          start: date.start,
          end: date.end,
        })
        .then((res) => {
          if (res.state) {
            setTimeout(() => {
              for (let a in res.data) {
                let item = res.data[a];
                for (let b in item.list) {
                  let index = item.list[b];
                  item["" + index.tid] = index.value;
                }
              }
              this.info.vital.list = res.data;
            }, 500);
            setTimeout(() => {
              if (res.data.length > 0) this.buildDailyTestCharts();
              this.info.vital.loading = false;
            }, 1000);
          }
        })
        .catch(() => {
          this.info.vital.loading = false;
        });
    },
    getDailyTestChartsList() {
      let option = {};
      let data = {};
      let list = this.info.vital.list;
      for (let a in list) {
        let index = list[a].list;
        for (let b in index) {
          let item = index[b];
          let date = [];
          let value = [];
          if (data[item.tid]) {
            value = data[item.tid].value;
            date = data[item.tid].date;
          }
          value.push(parseFloat(item.value));
          date.push(list[a].date);
          data[item.tid] = { date, value };
        }
      }
      let temps = this.info.vital.temp;
      for (let i in temps) {
        let temp = temps[i];
        if (!temp.chart) continue;
        let sub = temp.list;
        if (data[sub[0].id] == undefined) {
          temp.hide = true;
          continue;
        }
        // 竖轴
        let yAxis = { type: "value", scale: true };
        if (sub.length == 1) yAxis.name = sub[0].unit;
        else {
          let unit = "";
          for (let x in sub) {
            if (unit == "") unit = sub[x].unit;
            else if (unit != sub[x].unit) unit = "NO";
          }
          if (unit != "" && unit != "NO") yAxis.name = unit;
        }
        // 数值
        let series = [];
        for (let x in sub) {
          let value = [];
          if (data[sub[x].id]) value = data[sub[x].id].value;
          series.push({
            name: sub[x].label,
            type: "line",
            data: value,
          });
        }
        option[temp.id] = {
          title: {
            text: temp.name,
            x: "center",
            textStyle: {
              fontSize: 14,
              color: "#333",
            },
            top: 5,
          },
          tooltip: {
            trigger: "axis",
          },
          xAxis: {
            type: "category",
            data: data[sub[0].id].date,
          },
          grid: {
            top: "50px",
            left: "35px",
            right: "15px",
            bottom: "30px",
          },
          yAxis,
          series,
        };
      }
      return option;
    },
    buildDailyTestCharts() {
      let list = this.getDailyTestChartsList();
      for (let id in list) {
        let chartObj = echarts.init(document.getElementById("vital_" + id));
        chartObj.setOption(list[id]);
      }
    },
    changeVitalDate() {
      this.queryDailyTest();
    },
    buildDailyTestDate() {
      let start = this.info.vital.date[0];
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);
      start.setMilliseconds(0);
      let end = this.info.vital.date[1];
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);
      end.setMilliseconds(999);
      return { start: start.getTime(), end: end.getTime() };
    },
    switchTab(tab) {
      let name = tab.props.name;

      let path = window.location.hash;
      path = path.substring(
        1,
        path.indexOf("?") == -1 ? path.length : path.indexOf("?")
      );
      this.$router.replace({ path, query: { tab: name } });

      if (name === "follow") {
        if (this.info.follow.list.length == 0) this.queryFollowNumber();
      } else if (name === "drug") {
        console.log("drug");
      } else if (name === "vital") {
        if (this.info.vital.list.length == 0) this.queryDailyTest();
      } else if (name === "chat") {
        if (this.info.problem.list.length == 0) this.getProblemNumber();
      }
    },
    openFollow(row) {
      this.$router.push(
        "/patient/" + this.pid + "/follow?id=" + (row.id ? row.id : row)
      );
    },
    openAlert(item) {
      let date = item.date.split("-");
      this.$alert(
        item.content,
        date[0] + "年" + date[1] + "月" + date[2] + "日之后生效",
        {
          confirmButtonText: "关闭",
        }
      );
    },
    openChat(row) {
      this.$router.push("/chat/" + row.id);
    },
    fullScreen(id) {
      this.info.vital.fullScreen = true;
      setTimeout(() => {
        let list = this.getDailyTestChartsList();
        echarts.init(document.getElementById("vital_fullscreen")).dispose();
        let chartObj = echarts.init(
          document.getElementById("vital_fullscreen")
        );
        chartObj.setOption(list[id]);
      }, 300);
    },
  },
  mounted() {
    if (this.$route.query.tab) this.select = this.$route.query.tab;
    this.init();
  },
};
</script>

<style>
.patient-tabs .el-tabs__nav-scroll {
  padding: 1.5px 10px;
}

.patient-tabs .el-tabs__header.is-top {
  margin-bottom: 0;
}

.patient-tabs > .el-tabs__content {
  padding: 0;
}

.patient-tabs .follow-list .el-timeline-item__tail {
  height: 115%;
}

.patient-tabs .el-table .cell {
  padding: 0 5px 0 10px;
}

.patient-tabs .el-affix--fixed {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  padding: 5px 0;
}
</style>

<style scoped>
.follow-list .card {
  transition: all ease-out 0.3s;
  background-color: #f4f4f4;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: none;
  cursor: pointer;
  padding: 10px;
}

.follow-list .card:hover {
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
  background-color: #f0f0f0;
}

.follow-list .el-timeline-item {
  padding-bottom: 0;
}

.follow-list .follow-item {
  display: flex;
}

.to-top {
  display: none;
}

.el-affix--fixed .to-top {
  display: block;
}

.chart-list {
  display: inline-flex;
}

.chart-item {
  background-color: #f4f4f4;
  margin: 0 5px 10px 5px;
  border-radius: 8px;
  height: 230px;
  width: 300px;
}

.chart-tips {
  padding-top: 100px;
  text-align: center;
  font-size: 14px;
  color: #999;
}

.progress {
  border-bottom: 1px solid #e3e3e3;
  text-align: center;
  padding: 10px 10vw;
}

.progress .plan-number,
.progress .state-box {
  font-size: 14px;
}

.progress .plan-number {
  padding: 2px 0;
  color: #999;
}

.progress .state-complete {
  color: #329700;
}

.progress .state-overdue {
  color: #c98317;
}

.plan-box {
  font-size: 14px;
}

.plan-item {
  border-bottom: 1px solid #e3e3e3;
  min-height: 90px;
  padding: 10px;
}

.plan-tips {
  justify-content: center;
  align-items: center;
  display: flex;
  color: #999;
  height: 90px;
  width: 100%;
}

.plan-date {
  font-weight: 700;
  margin-left: 5px;
  font-size: 18px;
}

.overall {
  border-radius: 100px 0 0 100px;
  background-color: #ffc400;
  padding: 1px 5px 2px 5px;
  margin: 5px -10px 0 0;
  align-items: center;
  font-size: 14px;
  display: flex;
  color: #fff;
}

.overall > i {
  font-size: 22px;
}

.sub-box {
  display: flex;
}

.sub-box .plan-item {
  border-top: none;
  width: 50%;
}

.sub-box .plan-item:first-child {
  border-right: 1px solid #e3e3e3;
}

.full-screen {
  position: absolute;
  z-index: 9;
  right: 8px;
  top: 3px;
}

#vital_fullscreen {
  height: calc(100vh - 90px);
  width: 100%;
}
</style>